import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submit(event) {
    const form = event.target.closest("form");
    if (form) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
      form.requestSubmit()
    }, 300)
    }
  }

}
